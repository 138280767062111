import React, { useState, useEffect } from 'react';
import {
  UnlockOutlined,
  LockOutlined,
  UserOutlined,
  DownOutlined
} from '@ant-design/icons';
import {
  Layout,
  Row,
  Col,
  Popover
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import studentAction from '@iso/redux/student/actions';
import WebsiteService from '../../services/frontend/WebsiteService';
import StudentWrapper from './StudentWrapper.styles';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import images from '../../assets/images';
import '../../pages/frontend/12hour/CSS/popoverstyle.css';

const { Header } = Layout;

const TopbarStudent = () => {
  const [visible, setVisibility] = useState(false);
  const [websiteDetail, setWebsiteDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.userReducers);
  
  const dispatch = useDispatch();
  const history = useHistory();

  const pathname = window.location.pathname;

  const handleVisibleChange = () => setVisibility(!visible);

  const handleSignOut = () => {
    dispatch(studentAction.signout());
    dispatch({ type: 'STUDENT_LOGOUT' });
    history.push('/');
  };

  const fetchWebsiteDetail = async () => {
    setLoading(true);
    try {
      const res = await new WebsiteService().getWebsiteDetail();
      setWebsiteDetail(res);
      const siteType = res.site_type === 'dot' ? 'metro_dot' : res.site_type === '12hour' ? '12_hour_adi' : res.site_type;
      localStorage.setItem('site_type', siteType);
    } catch (error) {
      console.error('Error fetching website details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWebsiteDetail();
  }, []);

  const dropdownContent = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <Link className="isoDropdownLink" to="/account">
        <IntlMessages id="Student.Topbar.myAccount" />
      </Link>
      <Link className="isoDropdownLink" to="/edit-profile">
        <IntlMessages id="Student.Topbar.editProfile" />
      </Link>
      <Link className="isoDropdownLink" to="/payment-transaction">
        <IntlMessages id="Student.Topbar.myTransaction" />
      </Link>
    </TopbarDropdownWrapper>
  );

  return (
    <StudentWrapper>
      <Header className="header-wrapper">
        <div className="container">
          <Row align="middle">
            <Col span={12}>
              <a href="#" className="brand-logo">
                {!loading && (
                  <img
                    src={
                      websiteDetail?.website_logo && websiteDetail?.website_logo !== ''
                        ? websiteDetail?.website_logo
                        : images.logo
                    }
                    alt=""
                  />
                )}
              </a>
            </Col>

            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {(!user && pathname !== '/login' && pathname !== '/') && (
                <Row>
                  <div>
                    <a className="text-white d-flex align-items-center gap-2 topbar">
                      <UserOutlined style={{ fontSize: '23px' }} />
                      <span>Welcome Guest</span>
                    </a>
                  </div>
                  <Link
                    to="/"
                    className="text-white d-flex align-items-center gap-2 mx-3 topbar"
                  >
                    <LockOutlined style={{ fontSize: '23px' }} /> Sign In
                  </Link>
                </Row>
              )}

              {(user && pathname !== '/login' && pathname !== '/') && (
                <>
                  <Popover
                    trigger="click"
                    placement="bottom"
                    content={dropdownContent}
                    open={visible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <a className="text-white d-flex align-items-center gap-2 topbar">
                      <UserOutlined style={{ fontSize: '23px' }} />
                      <span className='d-flex align-items-center gap-1'>
                        {user.name}
                        <DownOutlined />
                      </span>
                    </a>
                  </Popover>

                  <div
                    className="text-white d-flex align-items-center gap-2 mx-3 topbar"
                    onClick={handleSignOut}
                  >
                    <UnlockOutlined style={{ fontSize: '23px' }} /> Sign Out
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Header>
    </StudentWrapper>
  );
};

export default TopbarStudent;
